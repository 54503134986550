/* eslint-disable import/no-anonymous-default-export */
import email from '../assets/email1.png';
import emailOnboarding from '../assets/onboarding_email.png';
import mobile from '../assets/mobile.png';
import api from '../assets/api.png';
import cpp from '../assets/cpp.png';
import css from '../assets/css.png';
import figma from '../assets/figma.png';
import flutter from '../assets/flutter.png';
import git from '../assets/git.png';
import graphql from '../assets/graphql.png';
import html from '../assets/html.png';
import javascript from '../assets/javascript.png';
import mu5 from '../assets/mu5.png';
import node from '../assets/node.png';
import python from '../assets/python.png';
import react from '../assets/react.png';
import redux from '../assets/redux.png';
import sass from '../assets/sass.png';
import typescript from '../assets/typescript.png';
import vue from '../assets/vue.png';

import about01 from '../assets/about01.png';
import about02 from '../assets/about02.png';
import about03 from '../assets/about03.png';
import about04 from '../assets/about04.png';

import profile from '../assets/profile.png';
import circle from '../assets/circle.svg';
import logo from '../assets/logo.png';

import adidas from '../assets/adidas.png';
import amazon from '../assets/amazon.png';
import asus from '../assets/asus.png';
import bolt from '../assets/bolt.png';
import nb from '../assets/nb.png';
import skype from '../assets/skype.png';
import spotify from '../assets/spotify.png';
import recyclex from '../assets/recyclex_new.png';
import recyclex2 from '../assets/2_white.png';
import vecrecycle from '../assets/vecrecycle.jpg';
import walking from '../assets/walking.png';
import enviroment from '../assets/enviroment.png';
import ecology from '../assets/ecology.png';
import recycleBin from '../assets/recycleBin.png';
import recycleBinColored from '../assets/recycleBin1.png';
import recycleSymbol from '../assets/recycleSymbol.png';
import consult from '../assets/consult2.png';
import services from '../assets/services.png';
import vision from '../assets/vision.png';
import mission from '../assets/mission.png';
import values from '../assets/values.png';

export default {
  email,
  emailOnboarding,
  mobile,
  api,
  cpp,
  css,
  figma,
  flutter,
  git,
  graphql,
  html,
  javascript,
  mu5,
  node,
  python,
  react,
  redux,
  sass,
  typescript,
  vue,
  about01,
  about02,
  about03,
  about04,
  profile,
  circle,
  logo,
  adidas,
  amazon,
  asus,
  bolt,
  nb,
  skype,
  spotify,
  recyclex,
  recyclex2,
  vecrecycle,
  walking,
  enviroment,
  recycleBin,
  recycleSymbol,
  ecology,
  consult,
  services,
  recycleBinColored,
  vision,
  mission,
  values,
};
